import React from 'react';
import styled, {css} from 'styled-components';

const Field = styled.input`
  padding: 1rem 1.5rem;
  display: block;
  font-size: 1.5rem;
  ${props => props.styling === "outlined" && css`
    border: 1px solid ${props => props.theme.colors.grayDarker};
    height: 4.5rem;
    background-color: #fff;
    width: 100%;
  ` 
  }

  ${props => props.styling === "filled" && css`
    border: none;
    height: 4.5rem;
    background-color: ${props => props.color ? props.color : "#EEF6FD"};
    width: 100%;
  ` 
  }

  ::placeholder {
    color: ${props => props.theme.colors.grayDarker};
  }
`

const TextArea = styled.textarea`
   font-size: 1.5rem;
   padding: 1rem 1.5rem;
   display: block;
   border: 1px solid ${props => props.theme.colors.grayDarker};
   background-color: #fff;
   height: 17rem;
   width: 100%;

   ${props => props.styling === "filled" && css`
    border: none;
    height: 24rem;
    background-color: ${props => props.color ? props.color : "#EEF6FD"};
    width: 100%;
  ` 
  }

   ::placeholder {
    color: ${props => props.theme.colors.grayDarker};
  }
`

const TextField = ({style, input = "text", bg, ...props}) => {

  if (input === "text") return (
    <Field styling={style} {...props} ></Field>
  ) 
  else return (
    <TextArea styling={style} bg={bg} {...props} ></TextArea>
  )
}

export default TextField;

