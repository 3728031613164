import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap'


import RowWrapper from '../utils/rowWrapper';

import Layout from '../components/layout';
import Hero from '../images/hero-contact.png'

import FormComponent from '../components/form/form';
 
const Container = styled.div`
 padding-top: 8rem;

 @media ${props => props.theme.breaks.down('md')} {
  padding-top: 3rem;
 }
`

const Header = styled.h3`
  font-size: 4rem;
  text-align: center;
  color: ${props => props.theme.colors.heading3};

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 2.5rem;
    margin-bottom: 2rem;
 }
`

const Main = styled.section`
  min-height: 60rem;
  display: flex;
  margin: 0 auto;
  /* margin-top: 5rem; */

  @media ${props => props.theme.breaks.down('md')} {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`

const Form = styled.div`
 flex: 1 0 auto;
 width: 50%;
 padding: 3rem 8rem;

 @media ${props => props.theme.breaks.down('md')} {
  width: 95%;
  padding: 0 1rem;
 }

`

const Image = styled.div`
 flex: 1 0 auto;
 width: 50%;
 padding: 0 5rem;
 perspective: 150rem;


 img {
   width: 100%;
   object-fit: contain;
   transform: scale(.8);
   filter: drop-shadow(1rem 1rem 4rem #00000022) saturate(90%);
   /* transform: rotateY(-15deg); */
 }

 @media ${props => props.theme.breaks.down('md')} {
  width: 70%;
  padding: 2rem 0;
  min-height: 25rem;
 }


`

const ContactUs = ({location}) => {

  const heroImg = useRef()

  useEffect(() => {
    gsap.fromTo(heroImg.current, {y: 100}, {y: 0, scale: 1, duration: 1, filter: "drop-shadow(1rem 1rem 5rem #00000038) saturate(130%)"})
  }, [])
 
 return (
  <Layout location={location.pathname} pageTitle="Novosoft - Contact Us">
  <Container>
    <RowWrapper>
    <Header>Contact Us</Header>

    <Main>
      <Form>
        <FormComponent />
      </Form>
      <Image><img ref={heroImg} src={Hero} alt="" /></Image>
    </Main>
    </RowWrapper>
  </Container>
 </Layout>
 )
}

export default ContactUs;