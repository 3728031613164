import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby'

import TextField  from "../../utils/TextField";
import { encode } from '../../utils/util'
 
const Form = styled.form`
 width: 100%;
`
const Button = styled.button`
  background-color: ${props => props.theme.palette.secondary.main};
  color: #fff;
  display: block;
  width: 20rem;
  height: 6rem;
  text-align: center;
  font-size: 1.8rem;
  text-transform: uppercase;
  padding: 2rem 0;
  border-radius: 5px;
  border: none;
  margin-top: 5rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px 5px #00000011;
  }

  &:active {
    box-shadow: none;
  }

  
  @media ${props => props.theme.breaks.down('md')} {
    width: 100%;
    margin: 0 auto;
    margin-top: 5rem;
  }
`

const FieldContainer = styled.div`
  margin-top: 1.5rem;

  label {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 150.1%;
    letter-spacing: 0.02em;
    color: ${props => props.theme.colors.darkText};

    span {
      color: ${props => props.theme.colors.urgent};
      font: inherit;
    }
  }
`

const FormComponent = () => {

  const [state, setState] = useState({
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        console.log("navigating");
        navigate(form.getAttribute('action'))
      })
      .catch((error) => alert(error))
  }
 
 return (
 <Form
  name="contact"
  method="post"
  action="/success"
  data-netlify="true"
  data-netlify-honeypot="bot-field"
  onSubmit={handleSubmit}
 >

<input type="hidden" name="contact" value="contact" />
  <p hidden>
    <label>
      Don’t fill this out: <input name="bot-field" onChange={handleChange} />
    </label>
  </p>

    <FieldContainer>
      <label>Full Name<span>*</span></label>
    <TextField onChange={handleChange} name="name" style="filled" required
    //  placeholder="Full Name"
    ></TextField>
    </FieldContainer>

    <FieldContainer>
      <label>E-mail<span>*</span></label>
    <TextField onChange={handleChange} name="email" type="email" required style="filled" 
    // placeholder="E-mail"
    ></TextField>
    </FieldContainer>

    <FieldContainer>
      <label>Message<span>*</span></label>
    <TextField onChange={handleChange} name="message" required
    // placeholder="Message"
      type="text" style="filled" input="textarea"></TextField>
    </FieldContainer>

    <Button type="submit">Submit</Button>
 
 </Form>
 )
}

export default FormComponent;